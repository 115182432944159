exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jarenlange-ervaring-tsx": () => import("./../../../src/pages/jarenlange-ervaring.tsx" /* webpackChunkName: "component---src-pages-jarenlange-ervaring-tsx" */),
  "component---src-pages-klusjes-tsx": () => import("./../../../src/pages/klusjes.tsx" /* webpackChunkName: "component---src-pages-klusjes-tsx" */),
  "component---src-pages-laadpalen-tsx": () => import("./../../../src/pages/laadpalen.tsx" /* webpackChunkName: "component---src-pages-laadpalen-tsx" */),
  "component---src-pages-nieuwbouw-en-renovaties-tsx": () => import("./../../../src/pages/nieuwbouw-en-renovaties.tsx" /* webpackChunkName: "component---src-pages-nieuwbouw-en-renovaties-tsx" */)
}

